import * as React from 'react';

function SiteLogo(props) {
  return (
    <svg
      fill="#fff"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      clipRule="evenodd"
      viewBox="0 0 186 111"
      {...props}
    >
      <defs>
        <linearGradient
          id="gradient"
          x1={0}
          x2={1}
          y1={0}
          y2={0}
          gradientTransform="rotate(-90 92.476 .399) scale(35.62)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#a7a9ac" />
          <stop offset={0.01} stopColor="#a9aaad" />
          <stop offset={0.21} stopColor="#cecfd1" />
          <stop offset={0.4} stopColor="#e9e9ea" />
          <stop offset={0.57} stopColor="#f9f9fa" />
          <stop offset={0.7} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
      <path
        fill="#00b4aa"
        fillRule="nonzero"
        d="M61.41 46.085l5.78-4.9v-7.57l-4.73 4a3 3 0 00-1 2.26v6.2"
      />
      <path
        fill="#007fa1"
        fillRule="nonzero"
        d="M67.19 64.635l-5.78-2.61v-15.94l5.78-4.9v23.45m-18.77-7.53v-6.22a2.92 2.92 0 011-2.23l4.72-4v7.6l-5.77 4.9"
      />
      <path
        fill="#0e5c97"
        fillRule="nonzero"
        d="M54.19 62.315h-5.77v-5.21l5.77-4.9v10.11m0-10.06v-7.62l8.21-7a3.002 3.002 0 00-1 2.19v6.2l-7.21 6.23"
      />
      <path fill="none" d="M61.41 39.865v.04" />
      <path
        fill="#007fa1"
        fillRule="nonzero"
        d="M67.19 41.185v-7.57l8.26-7a3 3 0 00-1 2.25v6.2l-7.22 6.13"
      />
      <path
        fill="#00b4aa"
        fillRule="nonzero"
        d="M119.47 46.085l-5.77-4.9v-7.57l4.72 4a2.941 2.941 0 011 2.26v6.2"
      />
      <path
        fill="#007fa1"
        fillRule="nonzero"
        d="M113.69 64.635v-23.45l5.78 4.9v15.94l-5.78 2.61m18.77-7.53l-5.77-4.85v-7.62l4.73 4h.06-.05a2.941 2.941 0 011 2.23v6.22"
      />
      <path
        fill="#0e5c97"
        fillRule="nonzero"
        d="M132.46 62.315h-5.77v-10.11l5.77 4.9v5.21"
      />
      <path
        fill="#00b4aa"
        fillRule="nonzero"
        d="M106.47 62.315v-33.45a3 3 0 00-1-2.27l-13.11-11.08a3.029 3.029 0 00-3.84 0l-13.06 11.08a3 3 0 00-1 2.27v33.45h5.72v-32.15l10.26-8.7 10.26 8.7v32.15h5.77"
      />
      <path
        fill="#0e5c97"
        fillRule="nonzero"
        d="M126.69 52.255l-7.22-6.12v-6.2a2.941 2.941 0 00-1-2.17l8.2 7v7.49"
      />
      <path
        fill="#00b3aa"
        fillRule="nonzero"
        d="M119.47 39.855a3.002 3.002 0 00-1-2.22 2.941 2.941 0 011 2.17"
      />
      <path
        fill="#007fa1"
        fillRule="nonzero"
        d="M113.7 41.185l-7.22-6.13v-6.2a3 3 0 00-1-2.25l8.26 7v7.57"
      />
      <path
        fill="#bcbec0"
        fillRule="nonzero"
        d="M38.3 46.255a1.015 1.015 0 01-1.124-.992 1 1 0 01.454-.838L90.1.255c.379-.34.961-.34 1.34 0l52.45 44.22a1 1 0 01-.67 1.83l-104.92-.05m52.47-43.9l-49.63 41.84h99.24L90.77 2.355z"
      />
      <path
        fill="#1d4f93"
        fillRule="nonzero"
        d="M44.69 60.255l-7.82-.92 12.55-10.65a2.998 2.998 0 00-1 2.2v6.22l-3.73 3.15"
      />
      <path
        fill="#0089a4"
        fillRule="nonzero"
        d="M48.42 50.885c.01-.841.373-1.64 1-2.2a2.92 2.92 0 00-1 2.23"
      />
      <path
        fill="#1d4f93"
        fillRule="nonzero"
        d="M137.89 61.695l-5.43-4.59v-6.22a3.002 3.002 0 00-1-2.19l12.16 10.32a5.012 5.012 0 01-.94 1.19h-.1a4.17 4.17 0 01-.82-.24 4.138 4.138 0 00-1.28-.24c-.87 0-1.83.4-2.61 1.95"
      />
      <path
        fill="#0089a4"
        fillRule="nonzero"
        d="M132.46 50.885a2.941 2.941 0 00-1-2.23h.05a3.002 3.002 0 011 2.19"
      />
      <path
        fill="#bcbec0"
        fillRule="nonzero"
        d="M30.26 101.985h-5.75l-.05.06v8.43l.05.06h1.06l.05-.06v-3.66h4.52l.05-.06v-.92l-.05-.05h-4.52v-2.66h4.64l.05-.05v-1.03l-.05-.06m8.5.01h-1.5l-3.41 8.44h1.11l.71-1.75h4.67l.72 1.75h1.1l-3.5-8.43m-2.65 5.7l1.93-4.73 1.94 4.73h-3.87zm21.26-5.72h-1.84l-.04.03-3.28 7.12-3.27-7.12-.04-.03h-1.84l-.05.06v8.43l.05.06h1.06l.05-.06v-7.53l3.48 7.56.04.03h1.06l.04-.04 3.47-7.55v7.53l.05.06h1.06l.05-.06v-8.43l-.05-.06m6.59 0h-1.07l-.05.06v8.43l.05.06h1.07l.05-.06v-8.43l-.05-.06m11.41 7.4h-4.73v-7.34l-.05-.06h-1.06l-.05.06v8.43l.05.06h5.84l.05-.06v-1.04l-.05-.05m9.65-7.4l-.04.03-2.74 3.88-2.73-3.88-.04-.03h-1.24l-.04.03v.06l3.43 4.9v3.5l.05.06h1.06l.05-.06v-3.5l3.54-4.9.01-.06-.05-.03h-1.26m21.5 0h-1.06l-.06.06v3.46h-5.38v-3.46l-.05-.06h-1.06l-.06.06v8.43l.06.06h1.06l.05-.06v-3.83h5.38v3.83l.06.06h1.06l.05-.06v-8.43l-.05-.06m9.45-.06h-1.29c-2.34 0-3.1.72-3.1 2.88v3c0 2 .84 2.78 3.1 2.78h1.27c2.26 0 3.1-.75 3.1-2.78v-3c0-2.16-.75-2.86-3.1-2.88m1.93 5.69c0 1.65-.59 1.82-1.93 1.83h-1.27c-1.35 0-1.94-.18-1.94-1.83v-2.6c0-1.58.36-1.94 1.93-1.94h1.27c1.57 0 1.93.36 1.93 1.94l.01 2.6zm16.59-5.63h-1.84l-.05.03-3.28 7.12-3.26-7.12-.05-.03h-1.84l-.05.06v8.43l.05.06h1.06l.06-.06v-7.53l3.47 7.56.05.03h1.05l.05-.04 3.46-7.55v7.53l.05.06h1.07l.05-.06v-8.43l-.05-.06m11.16 0h-6.03l-.05.06v8.43l.05.06h6.03l.05-.06v-1.08l-.05-.05h-4.91v-2.7h4.69l.06-.06v-.99l-.06-.05h-4.69v-2.39h4.91l.05-.05v-1.06l-.05-.06m8.5 3.65h-.14c-1.79 0-2.59-.12-2.59-1.4 0-.66.18-1.24 2.31-1.24h3.14v-1h-3.11c-2.39 0-3.51.25-3.51 2.5 0 2 1.34 2.11 2.89 2.19h1.19c1.32.07 1.87.1 1.87 1.35.047.384-.057.77-.29 1.08-.33.32-1 .36-2.25.36-1.43 0-2.46 0-3.07-.06h-.1v1h3.23c2.41 0 3.66-.11 3.66-2.51s-1.08-2.34-3.22-2.45"
      />
      <path
        fill="url(#gradient)"
        d="M60.876 81.485l12.574 12.58a.641.641 0 01-.58.38h-8.53a.68.68 0 01-.48-.21l-12.89-12.75h-5.56v12.32c0 .351-.289.64-.64.64h-6.71a.653.653 0 01-.65-.65v-34.33a.658.658 0 01.65-.64H63.2c4.74 0 7.24 2.52 7.24 7.3v8.06c0 4.77-2.5 7.3-7.24 7.3h-2.324zM23.96 94.445H.63h.02a.653.653 0 01-.65-.65v-34.3a.68.68 0 01.67-.67h25.11c4.74 0 7.25 2.53 7.25 7.3v4.2c0 2.81-1.17 4.81-3.38 5.81h-.01c2.37 1.09 3.64 3.32 3.62 6.57v4.38c0 3.36-1.62 7.36-9.3 7.36zm90.96 0a.652.652 0 01-.64-.65v-34.33a.648.648 0 01.6-.64h30.07a.65.65 0 01.64.64v5.8l-.01.04v.01c0 .351-.289.64-.64.64h-22.66v6.35h12.99a.65.65 0 01.64.64v5.88l.01-.04v.01c0 .346-.284.63-.63.63h-13.01v7.29h23a.658.658 0 01.64.65v6.43a.658.658 0 01-.64.65h-30.36zm70.88-34.98v34.33c0 .357-.293.65-.65.65h-4.65a.661.661 0 01-.46-.19l-21.53-21.22c-.42-.41-.82-.83-1.21-1.26.13 1.34.13 2.46.13 3.46v18.56a.658.658 0 01-.64.65h-6.13a.643.643 0 01-.64-.64v-34.34a.65.65 0 01.64-.64h4.66a.671.671 0 01.45.18l1.6 1.58 21.01 21.003V59.465a.658.658 0 01.65-.64h6.12a.658.658 0 01.65.64zm-76.52-.25a.636.636 0 00-.59-.39h-6.81a.658.658 0 00-.65.64v27.25H83.04v-27.25a.65.65 0 00-.64-.64h-6.82a.65.65 0 00-.59.39v27.09c.06 3.05.62 5.1 1.7 6.27a5.617 5.617 0 002.81 1.47h.2l.46.09.39.06h23.19l.38-.06.47-.09h.2a5.642 5.642 0 002.8-1.47h.06c1.08-1.17 1.63-3.22 1.7-6.27v-27.09h-.07zM7.96 86.955h14.99a3.836 3.836 0 002-.34 2.649 2.649 0 00.65-2.07v-2.59a2.642 2.642 0 00-.66-2.06 3.836 3.836 0 00-2-.34H7.96v7.4zm37.45-12.6h14.71a3.73 3.73 0 001.84-.28 2.293 2.293 0 00.58-1.81v-4.14c0-.93-.19-1.54-.58-1.81a3.653 3.653 0 00-1.84-.29H45.41v8.33zm-37.45-1.73h14.98c2.2 0 2.49-.97 2.49-1.82v-3.27c0-.83-.29-1.83-2.49-1.83H7.96v6.92z"
      />
    </svg>
  );
}

export { SiteLogo };
